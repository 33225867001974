// This file should import site-wide things
// bootstrap javascript
import "bootstrap";
import "@/scss/common.scss";


// add scroll check
if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
) {
    let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
            document.getElementById("header").classList.add("header-not-at-top");
        } else {
            document.getElementById("header").classList.remove("header-not-at-top");
        }
    });
    observer.observe(document.querySelector("#observer"));
}